import React from "react"
// import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import Layout, { Body } from "../Shared/Layout"
import transformImageUri from "../../utils/transformImageUri"
import SEO from "../Shared/Seo"

import styles from "./TechnologyPage.module.scss"

const TechnologyPage = ({ technology }) => {
  const { title, description } = technology

  return (
    <Layout>
      <SEO
        title={`Technology - ${title}`}
        description="The right technology stack is vital for the product’s success. It directly impacts the cost and reliability of the end-product. Aramaze expertise guarantees you receive the cost-efficient and optimal tech vision."
      />
      <Body className={styles.row}>
        <div className={styles.left}>
          <h1>{title}</h1>
          <div className={styles.content}>
            <ReactMarkdown
              source={description}
              transformImageUri={transformImageUri}
            />
          </div>
        </div>
        {/*   <div className={styles.right}>
          <Img fluid={image?.childImageSharp.fluid} />
        </div> */}
      </Body>
    </Layout>
  )
}

export default TechnologyPage
